<template>
	<div class="sidebar">
		<div class="logo">
			<img
				alt="Leviia Logo"
				src="/images/leviia/logo.svg"
			/>
		</div>
		<div class="menus">
			<Menu-Main />
			<Menu-Footer />
		</div>
	</div>
</template>

<style lang="scss" scoped>
.sidebar {
	grid-template-rows: 28px 1fr;
	@apply w-full bg-dark h-full rounded-2xl px-4 py-5 gap-[40px] text-light
    select-none grid justify-stretch;

	.logo {
		@apply flex justify-center items-center;
	}

	.menus {
		@apply flex flex-col h-full gap-[40px] w-full justify-between items-stretch;
	}
}
</style>
