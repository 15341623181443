<script lang="ts" setup>
import type { MenuSelector } from "~/types/menus";

const props = defineProps<{
	selectionsValue?: string;
	selections?: MenuSelector[];
}>();

const currentValue = ref(props.selectionsValue);
const choicesAmount = computed(() => {
	return props.selections?.length ?? 0;
});

function activeSelection (selection: MenuSelector): void {
	currentValue.value = selection.value;
	selection.action();
}
</script>

<template>
	<div class="item-selector">
		<div
			v-for="selection in selections"
			:key="selection.name"
			:class="{
				active: selection.value === currentValue
			}"
			class="selection"
			@click.stop="activeSelection(selection)"
		>
			{{ selection.name }}
		</div>
	</div>
</template>

<style lang="scss" scoped>
.item-selector {
	display: grid;
	grid-template-columns: repeat(v-bind(choicesAmount), 1fr);
	padding: 0.25rem;
	background-color: var(--color-cream);
	border-radius: 500rem;

	.selection {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0.25rem;
		border-radius: 500rem;
		cursor: pointer;
		transition: background-color 0.2s ease-in-out;

		&:hover {
			background-color: var(--color-grey);
		}

		&.active {
			background-color: var(--color-primary);
			color: var(--color-white);
		}
	}
}
</style>
