<script lang="ts" setup>
import type { MenuItemProps } from "~/types/menus";

const { t, locale } = useI18n();

const config = useRuntimeConfig();

const isSupportModalOpen = ref(false);

const productEmail = config.public.PRODUCT_EMAIL;
const supportEmail = config.public.SUPPORT_EMAIL;

const name = ref("menu.help");
const items = computed<MenuItemProps[]>(() => [
	{
		name: "menu.help_documentation",
		icon: "book",
		link: `https://wiki.leviia.com/${locale.value}/`,
		external: true
	},
	{
		name: "menu.help_ideas",
		icon: "light-bulb-on",
		action: (): void => {
			isSupportModalOpen.value = true;
		}
	},
	{
		name: "menu.help_support",
		icon: "message-text",
		link: `mailto:${supportEmail}`,
		external: true
	}
]);
</script>

<template>
	<nav class="menu">
		<Menu-Category
			:items="items"
			:name="name"
			icon="headset-help"
		/>
		<Modal
			:is-open="isSupportModalOpen"
			@close="isSupportModalOpen = false"
		>
			<template #title>
				<span class="text-secondary flex gap-2">
					<Icon icon="light-bulb-on" />
					{{ $t("modals.proposal_improvement.title") }}
				</span>
			</template>
			<p>
				{{ $t("modals.proposal_improvement.body.1") }}
			</p>
			<p v-html="t('modals.proposal_improvement.body.2', createEmailLink(productEmail))" />
		</Modal>
	</nav>
</template>

<style lang="scss" scoped>
.menu {
	@apply flex flex-col text-sm w-full;
}
</style>
